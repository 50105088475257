@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  overscroll-behavior-y: none;
}

@page {
  margin: 0.5cm 0;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
    overflow: visible;
  }

  thead,
  tfoot {
    @apply table-row-group;
  }

  .ReactCollapse--collapse {
    height: auto !important;
    overflow: initial !important;
  }
}

a,
button,
input,
select {
  @apply transition file:transition;
  @apply focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-focusRing;
  @apply disabled:opacity-100;
}

input {
  @apply caret-brand text-dark placeholder:text-light;
}

input[type='checkbox'] {
  @apply accent-brand;
}

.remove-default-spin, input[type='number']::-webkit-inner-spin-button,
.remove-default-spin, input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-search-cancel-button {
  @apply appearance-none;
}

input.no-arrows::-webkit-outer-spin-button,
input.no-arrows::-webkit-inner-spin-button {
  @apply hidden;
}

button {
  @apply text-left;
}

th {
  @apply text-left;
  @apply font-semibold;
}

@keyframes loading-skeleton {
  100% {
    transform: translateX(100%);
  }
}

.loading-skeleton {
  background-color: #f1f5f9;

  width: 100%;
  display: inline-flex;
  line-height: 1;

  position: relative;
  user-select: none;
  overflow: hidden;
  z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */
}

.loading-skeleton::after {
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(
    90deg,
    #f1f5f9,
    #ffffff,
    #f1f5f9
  );
  transform: translateX(-100%);

  animation-name: loading-skeleton;
  animation-direction: normal;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@layer base {
  img {
    @apply inline-block;
  }
}
