.sunburst-viz .tooltip {
  background-color: #4100bf !important;
  border-radius: 16px !important;
  font-size: 16px !important;
  padding: 0;
}

.sunburst-viz .tooltip {
  max-width: unset !important;
  fill: #fff !important;
  font-weight: bold !important;
}

.sunburst-viz .text-contour {
  stroke: none !important;
}

.sunburst-viz text {
  fill: white !important;
}

.sunburst-viz .main-arc {
  stroke-width: 2px;
}

.sunburst-viz .tooltip-wrapper {
  padding-bottom: 18px;
}

.sunburst-viz .tooltip-title {
  padding: 18px 18px 0 18px;
  text-wrap: normal !important;
  color: #fff !important;
  display: flex !important;
  justify-content: flex-start !important;
}

.sunburst-viz .tooltip-content {
  padding: 0 18px 0 18px;
  margin-top: 16px;
  color: #ffffff;
  font-weight: 400;
}
