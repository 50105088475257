.react-datepicker__aria-live {
  display: none;
}

.range-datepicker .react-datepicker__day-name,
.range-datepicker .react-datepicker__day {
  width: 50px;
  line-height: 50px;
  font-size: 16px;
}

.range-datepicker .react-datepicker__header,
.range-datepicker .react-datepicker__month-container,
.range-datepicker .react-datepicker__month-container,
.range-datepicker .react-datepicker {
  display: flex;
}

.range-datepicker .react-datepicker__header,
.range-datepicker .react-datepicker__month-container {
  flex-direction: column;
}

.react-datepicker__header {
  border-bottom: 1px solid #e5e7eb;
}

.range-datepicker .react-datepicker__month-container:nth-child(2) .react-datepicker__month,
.range-datepicker .react-datepicker__month-container:nth-child(2) .react-datepicker__day-names {
  margin-left: 10px;
}

.range-datepicker .react-datepicker__month-container:nth-child(2) .react-datepicker__day-names {
  margin-right: 15px;
}

.range-datepicker .react-datepicker__month-container:nth-child(3) .react-datepicker__month,
.range-datepicker .react-datepicker__month-container:nth-child(3) .react-datepicker__day-names {
  margin-right: 10px;
  margin-left: 15px;
}

.range-datepicker .react-datepicker__day-name,
.range-datepicker .react-datepicker__day {
  color: #000000;
  display: inline-block;
  width: 3rem;
  line-height: 3rem;
  text-align: center;
  margin-top: 0.166rem;
}

.month-picker .react-datepicker__month-text--in-selecting-range,
.range-datepicker .react-datepicker__day--in-range:hover,
.range-datepicker .react-datepicker__day--in-selecting-range,
.range-datepicker .react-datepicker__day--in-range {
  background-color: #4f00ff10;
}

.range-datepicker .react-datepicker__day--range-start:hover,
.range-datepicker .react-datepicker__day--selecting-range-start,
.range-datepicker .react-datepicker__day--range-end,
.range-datepicker .react-datepicker__day--range-start {
  border-radius: 100% 0 0 100%;
  outline: none;
  z-index: 1;
}

.range-datepicker .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-start):hover,
.range-datepicker .react-datepicker__day--range-end:hover,
.range-datepicker .react-datepicker__day--range-end {
  border-radius: 0 100% 100% 0;
  outline: none;
  z-index: 1;
}

.react-datepicker .react-datepicker__day--keyboard-selected:not(.react-datepicker__day--in-range):hover {
  border-radius: 100%;
  z-index: 1;
}

.range-datepicker .react-datepicker__day:hover {
  z-index: 1;
  cursor: pointer;
}

.range-datepicker .react-datepicker__day--in-range,
.range-datepicker .react-datepicker__day--range-start,
.range-datepicker .react-datepicker__day--range-end,
.range-datepicker .react-datepicker__day--selecting-range-start {
  font-weight: bold;
}

.range-datepicker .react-datepicker__day--outside-month,
.range-datepicker .react-datepicker__day--outside-month div {
  color: #ffffff;
}

.range-datepicker .react-datepicker__day--in-selecting-range,
.range-datepicker .react-datepicker__day--in-range,
.range-datepicker .react-datepicker__day--range-start,
.range-datepicker .react-datepicker__day--range-end,
.range-datepicker .react-datepicker__day--selecting-range-start,
.range-datepicker .react-datepicker__day--outside-month:has(div),
.range-datepicker .react-datepicker__day,
.react-datepicker .react-datepicker__day:not(.react-datepicker .react-datepicker__day--disabled, .range-datepicker .react-datepicker__day--in-range):has(div):hover,
.range-datepicker .react-datepicker__day--outside-month:has(div) {
  position: relative;
}

.range-datepicker .react-datepicker__day--selecting-range-start:not(.react-datepicker__day--outside-month):after,
.range-datepicker .react-datepicker__day--selected:not(.react-datepicker__day--outside-month):after,
.range-datepicker .react-datepicker__day:not(.react-datepicker__day--disabled, .react-datepicker__day--outside-month):hover:after,
.range-datepicker .react-datepicker__day--in-range:not(.react-datepicker__day--outside-month):hover:after,
.range-datepicker .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--outside-month):hover:after,
.range-datepicker .react-datepicker__day--in-range:not(.react-datepicker__day--outside-month):hover:after,
.range-datepicker .react-datepicker__day--range-start:not(.react-datepicker__day--outside-month):after,
.range-datepicker .react-datepicker__day--range-end:not(.react-datepicker__day--outside-month):after {
  cursor: pointer;
  border-radius: 100%;
  outline: 2px solid #4f00ff;
  position: absolute;
  width: 3rem;
  height: 3rem;
  top: 0;
  left: 0;
  z-index: -1;
  content: '';
}

.range-datepicker .react-datepicker__day--range-start:hover:after,
.range-datepicker .react-datepicker__day--range-end:hover:after,
.range-datepicker .react-datepicker__day--range-start:hover,
.range-datepicker .react-datepicker__day--range-end:hover {
  color: #000000;
}

.range-datepicker .react-datepicker__day--range-start:hover,
.range-datepicker .react-datepicker__day--range-end:hover,
.range-datepicker .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-start):hover {
  background-color: #4f00ff10;
}

.range-datepicker .react-datepicker__day--disabled:hover,
.range-datepicker .react-datepicker__day--disabled:not(.react-datepicker__day--outside-month),
.range-datepicker .react-datepicker__day--disabled {
  color: #d7d7d7;
}

.range-datepicker .react-datepicker__day--disabled:hover {
  cursor: not-allowed;
  background-color: unset;
}

.range-datepicker div:is(.react-datepicker__day):where(div>div:has(div:nth-child(2))) {
  pointer-events: none;
}

.range-datepicker .react-datepicker__day--outside-month {
  pointer-events: none;
}

.month-picker .react-datepicker,
.year-picker .react-datepicker {
  position: relative;
}

.month-picker .react-datepicker__year-wrapper,
.year-picker .react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.react-datepicker-popper .react-datepicker__triangle {
  display: none;
}

.react-datepicker-popper {
  z-index: 1000;
  border: 1px solid #e5e7eb;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
}

.double-datepicker .react-datepicker-popper > div {
  background-color: white;
}

.month-picker .react-datepicker,
.double-datepicker .react-datepicker,
.year-picker .react-datepicker {
  border: 1px solid #e5e7eb;
  border-radius: 18px;
  color: #737373;
  font-family: Altform, serif;
}

.month-picker .react-datepicker__input-container,
.month-picker .react-datepicker__input-container input,
.month-picker .react-datepicker-wrapper,
.range-datepicker .react-datepicker__input-container,
.range-datepicker .react-datepicker__input-container input,
.range-datepicker .react-datepicker-wrapper {
  display: flex;
  justify-content: space-between;
}

.month-picker .react-datepicker__input-container,
.month-picker .react-datepicker__input-container input,
.month-picker .react-datepicker-wrapper {
  width: 170px;
}

.range-datepicker .react-datepicker__input-container,
.range-datepicker .react-datepicker__input-container input,
.range-datepicker .react-datepicker-wrapper {
  width: 210px;
}

.range-datepicker .react-datepicker__close-icon,
.month-picker .react-datepicker__close-icon {
  width: 0;
}

.range-datepicker .react-datepicker__close-icon:before,
.month-picker .react-datepicker__close-icon:before {
  position: relative;
  right: 20px;
  content: '✕';
  color: #4f00ff;
  font-size: 14px;
  font-weight: bold;
}

.month-picker .react-datepicker__triangle,
.range-datepicker .react-datepicker__triangle,
.double-datepicker .react-datepicker__triangle,
.year-picker .react-datepicker__triangle {
  position: absolute;
  left: 0;
  transform: translate3d(55px, 0px, 0px);
  margin-top: -7px;
  margin-left: -4px;
}

.month-picker .react-datepicker__triangle:after,
.range-datepicker .react-datepicker__triangle:after,
.double-datepicker .react-datepicker__triangle:after,
.year-picker .react-datepicker__triangle:after {
  top: 0;
  border: 8px solid transparent;
  border-bottom-color: #fff;
  box-sizing: content-box;
  content: '';
  left: -7px;
  position: absolute;
  width: 1px;
  z-index: 1;
  border-top: none;
}

.month-picker .react-datepicker__triangle:before,
.range-datepicker .react-datepicker__triangle:before,
.double-datepicker .react-datepicker__triangle:before,
.year-picker .react-datepicker__triangle:before {
  top: -1px;
  border: 8px solid transparent;
  border-bottom-color: #e5e7eb;
  border-top: none;
  box-sizing: content-box;
  content: '';
  height: 0;
  left: -8px;
  position: absolute;
  width: 3px;
  z-index: -1;
}

.month-picker .react-datepicker__month-container {
  display: flex;
  flex-direction: column;
}

.month-picker .react-datepicker,
.year-picker .react-datepicker {
  background-color: white;
}

.month-picker .react-datepicker__month {
  display: grid;
  grid-column: 4;
  text-align: center;
  gap: 5px;
}

.month-picker .react-datepicker__month-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.month-picker .react-datepicker__month-text--in-selecting-range,
.month-picker .react-datepicker__month-text--in-range,
.month-picker .react-datepicker__month--in-range {
  background-color: #ede7ff;
}

.month-picker .react-datepicker__month-text {
  border: 1px solid transparent;
  padding: 0 5px 0 5px;
}

.month-picker .react-datepicker__month-text--selecting-range-start,
.month-picker .react-datepicker__month-text--range-end,
.month-picker .react-datepicker__month-text--range-start,
.month-picker .react-datepicker__month--range-end,
.month-picker .react-datepicker__month--range-start {
  background-color: #ede7ff;
  border: 1px solid #4f00ff;
  border-radius: 4px;
}

.month-picker .react-datepicker__header,
.year-picker .react-datepicker__header {
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: 300px;
}

.year-picker .react-datepicker__year {
  text-align: center;
}

.year-picker .react-datepicker__year-wrapper {
  display: flex;
  justify-content: center;
  gap: 5px;
  padding: 6px;
}

.year-picker .react-datepicker__year-text {
  display: inline-block;
  margin: 2px;
  width: 4rem;
}

.month-picker .react-datepicker__month-text:hover:not(.react-datepicker__month-text--disabled) {
  cursor: pointer;
  border: 1px solid #4f00ff;
  border-radius: 4px;
}

.year-picker .react-datepicker__year-text:hover:not(.react-datepicker__year-text--disabled) {
  cursor: pointer;
  outline: 1px solid #4f00ff;
  border-radius: 4px;
}

.month-picker .react-datepicker__month-text--disabled,
.month-picker .react-datepicker__month--disabled,
.year-picker .react-datepicker__year-text--disabled {
  color: #cccccc;
  pointer-events: none;
}

.year-picker .react-datepicker__year-text--selected {
  background-color: #4f00ff;
  border-radius: 4px;
  color: #ffffff;
  font-weight: bold;
}

.react-datepicker__month {
  padding: 10px;
}

.double-datepicker .react-datepicker__month-container .react-datepicker__day-names {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.double-datepicker .react-datepicker__month-container .react-datepicker__day-names,
.double-datepicker .react-datepicker__week {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  text-align: center;
}

.double-datepicker .react-datepicker__day {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 41px;
  width: 41px;
  padding: 5px;
}

.double-datepicker .react-datepicker__day:not(.react-datepicker__day--disabled):hover {
  outline: 1px solid #4f00ff;
  border-radius: 100%;
  cursor: pointer;
}

.double-datepicker .react-datepicker__day--selected {
  background-color: #4f00ff;
  color: #ffffff;
  font-weight: bold;
  border-radius: 100%;
}

.double-datepicker .react-datepicker__day--disabled {
  color: #d7d7d7;
}

.double-datepicker .react-datepicker__day--disabled:hover {
  cursor: not-allowed;
}
